<template>
  <div class="station-alarm-container">
    <div class="station-alarm-tabs">
      <div v-for="(item, idx) in tabs" :key="idx" :class="['station-alarm-tab', curTab == item.code ? 'tab-active' : '']" @click="switchTab(item.code)">{{item.name}}</div>
      <div class="tab-span"></div>
      <div class="success-rate">{{showLang('cmd.success.rate')}}{{commandSuccessRate.success}} / {{commandSuccessRate.total}} = {{commandSuccessRate.rate}}%</div>
    </div>
    <div class="station-alarm-tabcontent">
      <template v-for="(item, idx) in tabs">
        <component v-if="item.code == curTab" :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</template>
<script>
import Channel from './Channel'
import Current from './Current'
import Powerbox from './Powerbox'
// import Light from './Light'
// import DoorLock from './DoorLock'
// import Lux from './Lux'
import Gateway from './Gateway'
// import Leakage from './Leakage'
import Energy from '@/components/control/box/Energy'
import LightNew from '@/components/control/light/Index'
import { mapGetters } from 'vuex'
import stateLt168 from './stateLt168/State'
export default {
  name: 'StationAlarmIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      tabs: [
        { code: 's1', name: '智能控制终端', com: Powerbox, args: {} },
        { code: 's16', name: '智能电表', com: Energy, args: {} },
        { code: 's2', name: '控制通道', com: Channel, args: {} },
        // { code: 's6', name: '门锁', com: DoorLock, args: {} },
        { code: 's3', name: '电流通道', com: Current, args: {} },
        // { code: 's7', name: '漏电通道', com: Leakage },
        // { code: 's8', name: '联动管理', com: Union },
        { code: 's4', name: '集中器', com: Gateway, args: {} },
        // { code: 's5', name: '旧单灯控制器', com: Light, args: {} },
        { code: 's7', name: '单灯控制器', com: LightNew, args: {type: 'common'} },
        { code: 's8', name: '115B', com: stateLt168, args: {} },
      ],
      curTab: 's1',
      single: false,
      commandSuccessRate: {
        total: 0,
        success: 0,
        rate: 0,
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
  },
  watch: {
  },
  mounted: function () {
    this.tabs= [
        { code: 's1', name:this.showLang('com.ope.nav.iq.terminal') , com: Powerbox, args: {} },
        { code: 's16',name:this.showLang('com.ope.nav.iq.meter') , com: Energy, args: {} },
        { code: 's2', name:this.showLang('com.ope.nav.channel') , com: Channel, args: {} },
        // { code: 's6', name:this.showLang('com.ope.nav.lock'), com: DoorLock, args: {} },
        { code: 's3', name:this.showLang('com.ope.nav.i.ch'), com: Current, args: {} },
        { code: 's4', name:this.showLang('com.ope.nav.concentrator') , com: Gateway, args: {} },
        { code: 's7', name:this.showLang('com.ope.nav.light.controller'), com: LightNew, args: {type: 'common'} },
        { code: 's8', name:this.showLang( 'com.ope.nav.lt168'), com: stateLt168, args: {} },
      ]
    window.eventBus.$on('commandSuccessRateChanged', params => {
      // console.log('cmd succ rate', params)
      this.$set(this, 'commandSuccessRate', params);
    });
  },
  destroyed: function () {
    window.eventBus.$off('commandSuccessRateChanged');
  },
  methods: {
    switchTab: function (type) {
      this.curTab = type;
    },
  }
}
</script>
<style scoped>
.tab-span{
  width: 100px;
  flex: auto;
}
.success-rate{
  width: 350px;
  flex: none;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bolder;
  line-height: 48px;
  padding-top: 10px;
}
.station-alarm-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  background-color: white;
}
.station-alarm-tabs {
  height: 48px;
  flex: none;
  display: flex;
  /* border: solid 1px red; */
}
.station-alarm-tab {
  padding: 2px 10px;
  cursor: pointer;
  background-color: #fff;
  color: #324252;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 48px;
}
.tab-active {
  color: #021629;
  font-weight: bold;
}
.tab-active::after {
  content: "";
  width: calc(100%);
  height: 1px;
  display: block;
  margin: -5px auto 0;
  border-bottom: 2px solid #3880fc;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
  /* padding-right: 40px; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>